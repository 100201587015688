<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import emitter from '/~/core/emitter'
import { useKyc, useKycComponent, useOtp } from '/~/extensions/otp/composables'
import OtpFactory from '/~/extensions/otp/composables/core/OtpFactory'
import { useUser } from '/~/composables/user/'
import VerificationHome, {
  type VerificationFieldItem,
} from './verification-home.vue'

const router = useRouter()
const route = useRoute()
const otp = OtpFactory.create('enrolment')
const { user, fetching: isFetchingUser, fetchUserDetails } = useUser()
const { isEnrolmentMobileChannelEnabled, isEnrolmentEmailChannelEnabled } =
  useOtp()
const { isKycEnabled } = useKyc()
const { kycService } = useKycComponent()

const back = computed(() =>
  route.meta?.previousHash
    ? { hash: route.meta?.previousHash }
    : { name: route.name }
)

const verificationFields = computed(() => {
  const fields: VerificationFieldItem[] = []

  if (isEnrolmentMobileChannelEnabled.value) {
    fields.push({
      type: 'email',
      value: user.value?.email,
      isVerified: user.value?.otpVerifications?.email.status === 'verified',
    })
  }

  if (isEnrolmentEmailChannelEnabled.value) {
    fields.push({
      type: 'mobile',
      value: user.value?.mobile,
      isVerified: user.value?.otpVerifications?.mobile.status === 'verified',
    })
  }

  if (isKycEnabled.value) {
    fields.push({
      type: 'document',
      loading: kycService.syncing,
      status: kycService.verificationSession?.status,
      errors: kycService.verificationSession?.errors,
      value: user.value?.fullName,
      isVerified: user.value?.verifications?.identity?.status === 'verified',
    })
  }

  return fields
})

async function doVerification(type: VerificationFieldItem['type']) {
  if (type === 'email' || type === 'mobile') {
    try {
      await otp.initiate(type)

      router.push({
        hash: `#profile-verification-${type}`,
      })
    } catch (error) {
      console.error(error)
    }
  } else {
    await kycService.createVerificationSession()
    await kycService.syncVerificationSessions()
    await fetchUserDetails()
  }
}

async function doVerificationCancel() {
  if (kycService.verificationSession) {
    await kycService.cancelVerificationSession(
      kycService.verificationSession.externalId
    )
    await kycService.syncVerificationSessions()
  }
}

async function updateStatus() {
  await kycService.syncVerificationSessions()
  await fetchUserDetails()
}

onMounted(async () => {
  if (isKycEnabled.value) {
    await fetchUserDetails()
  }

  emitter.on('notifications:identity_verification.successful', updateStatus)
})
</script>

<template>
  <verification-home
    :verification-fields="verificationFields"
    :do-verification="doVerification"
    :do-verification-cancel="doVerificationCancel"
    :is-loading="isFetchingUser"
    :back="back"
  />
</template>
